<template>
  <div v-if="section">
    <div class="row">
      <div class="col-md-6">
        <ColorInput v-model="section.BackgroundColor" label="Background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BorderColor" label="Border color" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.HoverBackgroundColor" label="Hover background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.HoverBorderColor" label="Hover border color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Roudness" label="Roudness" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.Color" label="Color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.FontSize" label="Font size" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Padding" label="Padding" />
      </div>
      <!-- <div class="col-md-6">
        <AlignInput v-model="section.Align" label="Align" />
      </div> -->
      <div v-if="type === 'Call'" class="col-md-12">
        <TextInput  v-model="languages.CallLabel" label="Call Label" />
      </div>
      <div v-if="type === 'Message'" class="col-md-12">
        <TextInput v-model="languages.MessageLabel" label="Message Label" />
      </div>
      <div v-if="type === 'Lead'" class="col-md-12">
        <TextInput  v-model="languages.LeadLabel" label="Subscribe Label" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import IconInput from './IconInput'
import AlignInput from './AlignInput'

export default {
  components: {
    ColorInput,
    NumberInput,
    IconInput,
    AlignInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
    type: {
      type: String,
      default: ''
    },
    languages: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style></style>
