<template>
  <form-wizard
    color="#1a76d2"
    :start-index="startIndex"
  >
    <tab-content
      title="Create Form"
      :before-change="() => $refs.createStep.validateStep()"
    >
      <FormCreateStep
        ref="createStep"
        :form="form"
        @done="(form) => $emit('form-changed', form)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content
      title="Form Builder"
      :before-change="() => $refs.builderStep.validateStep()"
    >
      <FormBuilderStep
        ref="builderStep"
        :mainLanguageIndex="mainLanguageIndex"
        :form="form"
        @done="(form) => $emit('form-changed', form)"
        @loading="loadingState"
      />
    </tab-content>
      <tab-content
      title="Lead Form Steps"
      :before-change="() => $refs.leadFromSelectStep.validateStep()"
    >
    <LeadFormSelectStep
        ref="leadFromSelectStep"
        :form="form"
        @done="(form) => $emit('form-changed', form)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content
      title="Get script"
    >
      <CodeStep
        :form="form"
      />
    </tab-content>
    <template slot="next">
      <button
        tabindex="-1"
        type="button"
        class="wizard-btn"
        style="color: #fff;background-color: #1a76d2;border-color: #1a76d2;"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Next</span>
      </button>
    </template>
    <template slot="finish">
      <router-link
        v-if="!form.new_keyword && form.automation_id"
        :to="{ name: 'business.automations.edit', params: { id: form.automation_id } }"
        class="wizard-btn ml-2" style="color: #fff;background-color: #1a76d2;border-color: #1a76d2;">
        Go To Automation
      </router-link>
      <a
        href="javascript:;"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #1a76d2;border-color: #1a76d2;"
        @click.prevent="handleOnComplete"
      >
        Finish
      </a>
    </template>
  </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormBuilderStep from './Components/FormBuilderStep.vue'
import FormCreateStep from './Components/FormCreateStep.vue'
import LeadFormSelectStep from './Components/LeadFormSelectStep.vue'
import CodeStep from './Components/CodeStep.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    FormCreateStep,
    FormBuilderStep,
    LeadFormSelectStep,
    CodeStep,
},

  props: {
    form: {
      type: [Object],
      default: null,
    },
    mainLanguageIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isLoading: false,
      couponScript: null,
      startIndex: this.$route.query.step == 2 ? 1 : 0
    }
  },

  mounted() {
  },

  methods: {
    loadingState(loading) {
      this.isLoading = loading
    },

    handleOnComplete() {
      this.$router.push({ name: 'business.embedded-forms.index', params: { install: this.form.install_id }})
    },
  },
}
</script>

<style scoped lang="scss">
  .widget-body {
    padding: 0;
  }
</style>