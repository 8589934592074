<template>
  <div>
    <ValidationObserver ref="createForm">
      <form class="createForm">
        <div class="row mb-4">
          <div class="col-md-6">
            <label class="input-label">Enable Lead Form</label>
            <vuestic-switch v-model="form.is_enable_lead_form" style="max-width: 300px;" :offcolor=true>
              <span slot="trueTitle">On</span>
              <span slot="falseTitle">Off</span>
            </vuestic-switch>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div v-if="form.is_enable_lead_form" class="row mb-4">
          <div class="col-md-6">
            <h6 class="mb-3">You can select Lead Form here</h6>
            <label class="field-label">Lead Form</label>
            <multiselect v-model="form.lead_form" :options="leadForms" :multiple="false" :close-on-select="true"
              :clear-on-select="false" :searchable="true" :allowEmpty="true" label="label"
              track-by="value">
            </multiselect>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect,
  },
  props: {
    form: {
      type: [Object],
      default: null,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      leadForms: []
    }
  },
  methods: {

    async validateStep() {
      let stepValid = await this.update()

      return stepValid
    },

    async update() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch('install/updateForm', this.form)
        .then((form) => {
          this.$emit('done', form)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },


    loadData() {
      this.isLoading = true
      const param = {
        is_active: 1,
        all: true
      }
      this.$store.dispatch('leadForm/allLeadForms', param)
        .then((res) => {
          this.leadForms = res.data.map(d => {
            return { value: d.id, label: d.name }
          })
        })
        .catch((e) => {
          this.isLoading = false
          this.$router.push({ name: 'business.installs.active' });
        })
    },
  },
};
</script>

<style lang="scss" scoped></style>
