<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <ColorInput v-model="section.SuccessColor" label="Color (Success)" />
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.ErrorColor" label="Color (Error)" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Padding" label="Padding" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.FontSize" label="Font Size" />
        </div>
        <div class="col-md-6">
          <AlignInput v-model="section.Align" label="Align" />
        </div>
        <div v-if="type === 'Message'" class="col-md-12 mb-4">
          <TextareaEmojiPicker v-model="languages.smsResponseTitle" label="Sms Response" :focused="true" :rows="3"
            ref="textareaEmojiSmsResponse" :showSendButton="false" :hasHoverEffect="false"
            :enabledMergeField="false"/>
        </div>
        <div v-if="type === 'Call'" class="col-md-12 mb-4">
          <TextareaEmojiPicker v-model="languages.callResponseTitle" label="Call Response" :focused="true" :rows="3"
            ref="textareaEmojiCallResponse" :showSendButton="false" :hasHoverEffect="false"
            :enabledMergeField="false"/>
        </div>
        <div v-if="type === 'Lead'" class="col-md-12 mb-4">
          <TextareaEmojiPicker v-model="languages.leadResponseTitle" label="Lead Response" :focused="true" :rows="3"
            ref="textareaEmojiLeadResponse" :showSendButton="false" :hasHoverEffect="false"
            :enabledMergeField="false"/>
        </div>
        <div class="col-md-12">
          <TextareaEmojiPicker v-model="languages.exceptionTitle" label="Exception" :focused="true" :rows="3"
            ref="textareaEmojiException" :showSendButton="false" :hasHoverEffect="false"
            :enabledMergeField="false"/>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <CheckboxInput v-model="section.IsEnableRedirect" label="Redirect" />
    </div>
    <div v-if="section.IsEnableRedirect" class="col-md-12">
      <TextInput v-model="section.RedirectUrl" label="Redirect URL" required />
      <div class="mt-2">
        <div class="checkbox abc-checkbox abc-checkbox-primary">
          <input
            type="checkbox"
            name="schedule"
            :id="`email-required`"
            value="Daily"
            v-model="section.IsRedirectBlankPage"
          />
          <label :for="`email-required`">
            <span class="abc-label-text">Open New Tab</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import CheckboxInput from './CheckboxInput'
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import AlignInput from './AlignInput'
import TextareaEmojiPicker from "../../../common/TextareaEmojiPicker";
export default {
  components: {
    ColorInput, NumberInput, AlignInput, TextareaEmojiPicker,
    CheckboxInput,
  },
  props: {
    section: {
      type: [Object],
      default: null,
    },

    type: {
      type: String,
      default: ''
    },
     languages: {
      type: [Object],
      default: null,
    },
  },
}
</script>
  
<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>
  