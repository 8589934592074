<template>
    <div class="form-group">
      <div class="switch-margin">
        <label class="control-label">{{ label }}</label>
        <vuestic-switch v-model="iValue" class="mt-1" :offcolor=true>
          <span slot="trueTitle">Yes</span>
          <span slot="falseTitle">No</span>
        </vuestic-switch>
      </div>
    </div>
  </template>
  
  <script>
import { get } from 'http';

  export default {
    props: {
      value: {
        required: false,
        type: [Boolean],
        default: false,
      },
  
      label: {
        required: false,
        type: [String],
        default: '',
      },
    },

    computed: {
      iValue: {
        get () {
          return this.value
        },

        set (value) {
          this.$emit('input', value)
        }
      }
    }
   
  }
  </script>
  
  <style lang="scss" scoped>
  .custom-checkbox-wrapper {
    user-select: none;
  }
  
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border: 2px solid #b4b4b4 !important;
    top: 2px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label .checkbox-custom::before {
    border: 2px solid #b4b4b4 !important;
  }


  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
    border-color: #1a76d2;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom {
    border: 2px solid #b4b4b4 !important;
  }
  </style>
  