<template>
  <div class="form-group">
    <div
      class="align-item"
      :class="{ active: value === 'left' }"
      @click="chooseAlign('left')"
    >
      <i class="fa fa-align-left"></i>
    </div>
    <div
      class="align-item"
      :class="{ active: value === 'center' }"
      @click="chooseAlign('center')"
    >
      <i class="fa fa-align-center"></i>
    </div>
    <div
      class="align-item"
      :class="{ active: value === 'right' }"
      @click="chooseAlign('right')"
    >
      <i class="fa fa-align-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [String],
      default: '',
    },
  },

  methods: {
    chooseAlign(align) {
      this.$emit('input', align)
    },
  },
}
</script>

<style lang="scss" scoped>
.align-item {
  display: inline-block;
  border: 1px solid transparent;
  padding: 5px 6px 0px 6px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 6px;
  text-align: center;
  i {
    color: rgba(0, 27, 72, 0.54);
    font-size: 20px;
  }
  &.active {
    border-color: rgba(0, 27, 72, 0.12);
    background-color: #1b76d2;
    i {
      color: #fff;
    }
  }
}
</style>
