<template>
  <div class="code-generation-step">
    <h3>Copy the widget code and install it on Your website</h3>
    <p
      >Copy the code below and paste it in your website code.</p
    >
    <div>
      <textarea
        ref="scriptText"
        v-model="form.script"
        name="code"
        cols="30"
        rows="6"
        class="form-control"
        readonly
        @click="copyScript"
      ></textarea>
      <div class="text-right mt-1">
        <a href="javascript:void(0)" @click="copyScript">
          Copy the code
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: {
    form: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      isLoading: false,
      email: '',
    }
  },

  methods: {
    copyScript () {
      this.$refs.scriptText.select()
      this.$refs.scriptText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
  },
}
</script>

<style lang="scss">
.code-generation-step {
  text-align: center;
  max-width: 968px;
  margin: 0 auto;
  .text-red {
    color: #bb3232;
  }
  textarea {
    resize: none;
    border: 0.0625rem solid #e7e7e7;
    &[readonly] {
      background: #e9ecef;
    }
  }
}
</style>
