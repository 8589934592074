<template>
  <div v-if="section" class="row">
    <div class="col-md-6 mb-3">
      <div class="row">
        <div class="col-md-12">
          <CheckboxInput v-model="section.IsEnableName" label="Show Name Field" />
        </div>
        <div class="col-md-12"></div>
        <div v-if="section.IsEnableName" class="col-md-12">
          <div class="checkbox abc-checkbox abc-checkbox-primary">
            <input
              type="checkbox"
              name="schedule"
              :id="`name-required`"
              value="Daily"
              v-model="section.IsRequiredName"
            />
            <label :for="`name-required`">
              <span class="abc-label-text">Is Required</span>
            </label>
          </div>
          <div class="checkbox abc-checkbox abc-checkbox-primary">
            <input
              type="checkbox"
              name="schedule"
              :id="`name-validation`"
              value="Daily"
              v-model="section.IsEnableNameValidation"
            />
            <label :for="`name-validation`">
              <span class="abc-label-text">Enable Validation</span>
            </label>
          </div>
          <TextInput v-model="section.NameLabel" label="Name Label" />
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="row">
        <div class="col-md-12">
          <CheckboxInput v-model="section.IsEnableEmail" label="Show Email Field" />
        </div>
        <div class="col-md-12"></div>
        <div v-if="section.IsEnableEmail" class="col-md-12">
          <div class="checkbox abc-checkbox abc-checkbox-primary">
            <input
              type="checkbox"
              name="schedule"
              :id="`email-required`"
              value="Daily"
              v-model="section.IsRequiredEmail"
            />
            <label :for="`email-required`">
              <span class="abc-label-text">Is Required</span>
            </label>
          </div>
          <TextInput v-model="section.EmailLabel" label="Email Label" />
        </div>
      </div>
    </div>
    <div v-for="contactField in contactFields" class="col-md-6 mb-3" :key="contactField.id">
      <div class="row">
        <div class="col-md-12">
          <CheckboxInput v-model="section[`Contact${contactField.id}IsEnable`]" :label="`Show ${contactField.name} Field`" />
        </div>
        <div class="col-md-12"></div>
        <div v-if="section[`Contact${contactField.id}IsEnable`]" class="col-md-12">
          <div class="checkbox abc-checkbox abc-checkbox-primary">
            <input
              type="checkbox"
              name="schedule"
              :id="`contact-${contactField.id}-required`"
              value="Daily"
              v-model="section[`Contact${contactField.id}IsRequired`]"
            />
            <label :for="`contact-${contactField.id}-required`">
              <span class="abc-label-text">Is Required</span>
            </label>
          </div>
          <TextInput v-model="section[`Contact${contactField.id}Label`]" label="Label" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BorderColor" label="Border" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.LabelColor" label="Label" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Padding" label="Padding" />
    </div>
    <div class="col-md-12">
      <TextInput v-model="section.PhoneLabel" label="Phone Label" />
    </div>
    <div v-if="type === 'Message'" class="col-md-12">
      <TextInput v-model="section.MessageLabel" label="Message Label" />
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import CheckboxInput from './CheckboxInput'

export default {
  components: {
    ColorInput,
    NumberInput,
    CheckboxInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },

    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    contactFields () {
      return this.$store.getters['auth/contactFields']
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>
