<template>
  <div :style="containerStyle">
    <form>
       <div v-if="form.input.IsEnableName" :style="containerInputStyle">
        <label>{{ languages.input.NameLabel }}</label>
        <input type="text" :style="inputStyle" readonly>
      </div>
      <div v-if="form.input.IsEnableEmail" :style="containerInputStyle">
        <label>{{ languages.input.EmailLabel || 'Email' }}</label>
        <input type="email" :style="inputStyle" readonly>
      </div>
      <div v-for="contactField in contactFields" :key="contactField.id">
        <div v-if="form.input[`Contact${contactField.id}IsEnable`]" :style="containerInputStyle">
          <label>{{ languages.input[`Contact${contactField.id}Label`] || contactField.name }}</label>
          <input type="email" :style="inputStyle" readonly>
        </div>
      </div>
      <div :style="containerInputStyle">
        <label>{{ languages.input.PhoneLabel }}</label>
        <div class="phone-container">
          <span class="code"><div class="flag"></div> <span class="arrow">▼</span></span>
          <input type="text" :style="inputStyle" readonly></input>
        </div>
      </div>
       <div v-if="type === 'Message'" :style="containerInputStyle">
        <label>{{ languages.input.MessageLabel }}</label>
        <textarea type="text" :style="inputStyle" rows="4" readonly></textarea>
      </div>
      <div :style="buttonContainerStyle">
        <button type="button" :style="buttonStyle" @mouseenter="isHover = true" @mouseleave="isHover = false">
         {{ languages.triggerButton[`${type}Label`] }}
        </button>
      </div>
      <div v-if="showResponseSection" class="response-wrapper" :style="responseContainerStyle" v-html="responseText">
      </div>
    </form>
  </div>
</template>

<script>

export default {
  props: {
    form: {
      type: [Object],
      default: null,
    },
      languages: {
      type: [Object],
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: '',
    }
  },

  data () {
    return {
      isHover: false,
    }
  },

  computed: {
    showResponseSection() {
      return this.selectedTab == 'response'
    },

    contactFields () {
      return this.$store.getters['auth/contactFields']
    },

    containerStyle () {
      return {
        width: `${this.form.styling.MaxWidth}px`,
        backgroundColor: this.form.styling.BackgroundColor,
        borderRadius: `${this.form.styling.Roudness}px`,
        padding: `${this.form.styling.Padding}px`,
        border: `1px solid ${this.form.styling.BorderColor}`,
      }
    },

    inputStyle () {
      return {
        width: '100%',
        backgroundColor: this.form.input.BackgroundColor,
        color: this.form.input.Color,
        border: `1px solid ${this.form.input.BorderColor}`,
        borderRadius: `${this.form.input.Roudness}px`,
        padding: `${this.form.input.Padding}px`,
      }
    },

    containerInputStyle () {
      return {
        marginBottom: `15px`,
        color: this.form.input.LabelColor,
      }
    },

    buttonStyle () {
      return {
        backgroundColor: this.isHover ? this.form.triggerButton.HoverBackgroundColor : this.form.triggerButton.BackgroundColor,
        color: this.form.triggerButton.Color,
        border: `1px solid ${this.isHover ? this.form.triggerButton.HoverBorderColor : this.form.triggerButton.BorderColor}`,
        borderRadius: `${this.form.triggerButton.Roudness}px`,
        fontSize: `${this.form.triggerButton.FontSize}px`,
        padding: `${this.form.triggerButton.Padding}px`,
        transition: `all 0.4s ease 0s`,
        width: '100%'
      }
    },

    buttonContainerStyle () {
      return {
        paddingTop: `15px`,
      }
    },

    responseContainerStyle() {
      return {
        paddingTop: `${this.form.response.Padding}px`,
        paddingBottom: `${this.form.response.Padding}px`,
        color: this.form.response.SuccessColor,
        textAlign: this.form.response.Align,
        fontSize: `${this.form.response.FontSize}px`,
      }
    },

    responseText() {
      let res = 'We received your message'
      if (this.type === 'Message')
        res = this.languages.response['smsResponseTitle']
      else if (this.type === 'Call')
        res = this.languages.response['callResponseTitle']
      else if (this.type === 'Lead')
        res = this.languages.response['leadResponseTitle']
      res = res.replace(/\n/g, "<br />")
      return res
    }
  }
}
</script>
  
<style lang="scss">
form {
  margin-bottom: 0;
}
.phone-container {
  position: relative;
  .code {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .arrow {
    transform: scaleY(.5);
    display: inline-block;
    color: #666;
  }

  .flag {
    background: url('./../../../assets/images/us.webp');
    width: 30px;
    height: 30px;
    background-size: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.response-wrapper {
  margin-top: 10px;
  a {
    color: inherit;
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>