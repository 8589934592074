<template>
  <vuestic-widget class="form-create-step">
    <ValidationObserver ref="createForm">
      <form class="createForm">
        <div class="row">
          <div class="col-md-6">
            <text-input name="name" v-model="form.name" label="Name" validate="required" />
          </div>
          <div class="col-md-6">
            <text-input name="website" v-model="form.website" label="Website" validate="required|url" />
          </div>
          <div class="col-md-6 mb-4">
            <label class="input-label">Type</label>
            <multiselect v-model="form.type" :options="types" :multiple="false" :close-on-select="true"
              :clear-on-select="false" :show-labels="false" :searchable="false" :preserve-search="true"
              :allowEmpty="false" label="label" track-by="value">
            </multiselect>
          </div>
          <div class="col-md-6 mb-4">
            <label class="input-label">Department</label>
            <multiselect v-model="form.department" :options="departments" :multiple="false" :close-on-select="true"
              :clear-on-select="false" :show-labels="false" :searchable="true" :allowEmpty="true" label="label"
              track-by="value">
            </multiselect>
          </div>
          <div class="col-md-6">
            <text-input name="selector" v-model="form.selector" label="Selector"
              tooltip="Please input Class name or ID name of html element you want to insert the form into. ( e.g `.form_div` - Class Name, `#form_div` - ID name ). You can leave this blank and form will be added right after the script" />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { formEmbeddedTypes } from '../../../../constants'

export default {
  components: {
    Multiselect,
  },

  props: {
    form: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      types: JSON.parse(JSON.stringify(formEmbeddedTypes)),
      departments: [],
    }
  },

  mounted() {
    this.getDepartments()
  },

  methods: {
    getDepartments() {
      this.$store
        .dispatch("department/all")
        .then((data) => {
          this.departments = data.map(d => {
            return { value: d.id, label: d.name }
          })
        })
        .catch((err) => { });
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.updateOrCreate()
        }
      })

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch(
          this.form.id ? 'install/updateForm' : 'install/createForm',
          this.form
        )
        .then((form) => {
          if (!this.form.id) {
            this.$router.push({ name: 'business.embedded-forms.update', params: { install: form.install_id, id: form.id }, query: { step: 2 } })
          }

          this.$emit('done', form)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },

    newContact() {
      this.coupon.notifications.push({ email: null, phone: null, enable_email: true, enable_phone: true, })
    },

    deleteContact(index) {
      this.coupon.notifications.splice(index, 1)
    }
  },
}
</script>

<style lang="scss">
.form-create-step {
  .widget-body {
    padding: 0px;
  }

  .card {
    border-radius: 0px;
  }
}
</style>