<template>
  <vuestic-widget v-if="form" class="form-builder-step">
    <div class="editor-container">
      <div class="preview-box">
        <div class="preview-container">
          <Form
            ref="widget"
             :languages="form.sections.languageList[mainLanguageIndex].value"
            :form="form.sections"
            :selected-tab="selectedTab"
            :type="form.type_str"
          />
        </div>
      </div>
      <div class="design-box">
        <div role="tablist" class="custom-accordion ml-4">
          <b-card
            v-for="section in sections"
            :key="section.key"
            no-body
          >
            <b-card-header header-tag="header" role="tab" class="accordion-header">
              <a 
                href="javascript: void(0);"
                class="text-black header-link"
                @click="onTabClick(section.key)"
                :class=" {'active' : selectedTab == section.key} "
              >
                <h5
                  class="m-0 font-size-14 d-flex justify-content-between"
                >
                  {{ section.label }}
                  <i v-if="selectedTab !== section.key"
                    class="fa fa-chevron-down"
                  ></i>
                </h5>
              </a>
            </b-card-header>
            <b-collapse
              accordion="accordion"
              role="tabpanel"
              :visible="selectedTab === section.key"
            >
              <b-card-body >
                <component
                  :is="section.component"
                  :languages="form.sections.languageList[mainLanguageIndex].value[section.key]"
                  :section="form.sections[section.key]"
                  :type="form.type_str"
                />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
import Form from '../Form'
import TriggerButtonSection from './TriggerButtonSection'
import StylingSection from './StylingSection'
import InputSection from './InputSection'
import ResponseSection from './ResponseSection'

export default {
  components: {
    TriggerButtonSection,
    StylingSection,
    InputSection,
    Form,
    ResponseSection,
  },

  props: {
    form: {
      type: [Object],
      default: null,
    },
     mainLanguageIndex: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Styling',
          component: StylingSection,
        },
        {
          key: 'input',
          label: 'Input',
          component: InputSection,
        },
        {
          key: 'triggerButton',
          label: 'Trigger button',
          component: TriggerButtonSection,
        },
        {
          key: 'response',
          label: 'Response',
          component: ResponseSection,
        },
      ],
      selectedTab: null,
    }
  },

  methods: {
    async validateStep() {
      let stepValid = await this.update()

      return stepValid
    },

    async update() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch('install/updateForm', this.form)
        .then((form) => {
          this.$emit('done', form)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },

    onTabClick (section) {
      this.selectedTab = section
    },
  },
}
</script>

<style lang="scss">

.form-builder-step {
  background-color: #f1f2f6;
  .widget-body {
    padding: 0px;
  }
  .card {
    border-radius: 0px;
    .card-header {
      padding: 0;
      .header-link {
        padding: 20px 20px;
        display: block;
        &.active {
          background-color: #1b76d2;
          color: #fff;
        }
      }
    }
    .card-body {
      margin-top: 18px;
    }
  }
}
.editor-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 500px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  .preview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    border-radius: 5px;
  }
  @media screen and (max-width: 992px) {
    .design-box {
      margin: 0 auto;
    }
    flex-direction: column;
  }
}
</style>
  